import React from "react";
import Footer from "../../components/App/Footer";
import Layout from "../../components/App/Layout";
import Navbar from "../../components/App/Navbar";
import PageBanner from "../../components/Common/PageBanner";
import heroImage from "../../assets/images/financing-solutions/discounting-hero.jpg";
import QuoteCard from "../../components/Common/QuoteCard";
import discounting from "../../assets/images/financing-solutions/reverse-factoring.svg";
import platform from "../../assets/images/financing-solutions/platform.svg";
import platformDoc from "../../documents/SEA-Easy-Capital-Platform.pdf";
import whatisDoc from "../../documents/what-is-document.pdf";
import CardInfo from "../../components/Common/CardInfo";
import shopBuilding from "../../assets/images/financing-solutions/shop-building.svg";
import DiscountingCalculator from "../../components/App/DiscountingCalculator";
import { Link } from "gatsby";

const Discounting = () => {
  return (
    <Layout>
      <Navbar />
      <div className="page">
        <PageBanner
          pageTitle="Discounting"
          pageMainTitle="Pay your suppliers and save money at the same time"
          pageDescription="With our Discounting facilities, you can pay your suppliers as early as they wish and save money at the same time. As our customer, you can strengthen your supply chain as your suppliers reduce their Days Sales Outstanding (DSO)."
          buttonText="Learn More"
          image={heroImage}
          scrollDestination="#dso-calculator"
        ></PageBanner>

        <section className="simple-info-section">
          <div className="container">
            <div className="simple-info-section-item">
              <img src={shopBuilding} alt="" />
              <div className="simple-info-section-text">
                <h4 className="mb-3">
                  Invoice discounting is available to big and small businesses
                  alike
                </h4>
                <h4>
                  You do not need to own any high value assets to obtain funding
                </h4>
              </div>
            </div>
          </div>
        </section>

        {/* days sales calculator  */}
        <section className="bg-gray ptb-100" id="dso-calculator">
          <div className="container">
            <div className="section-title">
              <span className="sub-title">Estimate DSO</span>
              <h2>Days Sales Outstanding Calculator</h2>
              <p>
                Use our DSO (Days Sales Outstanding) Calculator to calculate
                benefits from higher cashflows and lower DSO
              </p>
            </div>
            <DiscountingCalculator />
          </div>
        </section>

        {/* apply for financing section  */}
        <section className="bg-white ptb-100" id="apply-for-financing">
          <div className="container">
            <div className="financing-right-away">
              <div className="section-title">
                <h2><b>Apply for financing right away</b></h2>
              </div>
              <div className="sub-title">
              <h4>You can apply for any of our financing services if you...</h4>
                <ol>
                  <li>
                    ...Want to reduce your DSO, making it comparable with your
                    industry peers
                  </li>
                  <li>...Want assurance of payment, on a particular day</li>
                  <li>
                    ...Want to simplify, streamline and digitize your receivables
                    processes
                  </li>
                </ol>
              </div>

              <Link to="/contact" className="default-btn no-icon mt-5">
                Contact Us
                <span />
              </Link>
            </div>
          </div>
        </section>

        {/* discounting and how platform works section  */}
        <section className="bg-blue ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <CardInfo
                  image={discounting}
                  title="What is Discounting?"
                  buttonText=" Learn More"
                  linkDestination={whatisDoc}
                />
              </div>
              <div className="col-lg-6 col-md-12">
                <CardInfo
                  image={platform}
                  title="How our Platform works"
                  buttonText="Learn More"
                  linkDestination={platformDoc}
                />
              </div>
            </div>
          </div>
        </section>

        {/* quote section  */}
        {/* <section className="quote-section">
          <div className="container">
            <QuoteCard
              quote="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
              personname="John Doe"
              company="ABC Corp."
            />
          </div>
        </section> */}
      </div>
      <Footer />
    </Layout>
  );
};

export default Discounting;
