import React from "react";
import Slider from "react-rangeslider";
import scrollTo from "gatsby-plugin-smoothscroll";

class DiscountingCalculator extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      accRecievableAmount: 0,
      netSalesValue: 0,
      period: 0,
      outstanding: 0,
    };
  }

  handleOnChangeAccRecievableAmount = (value) => {
    this.setState({
      accRecievableAmount: value,
    });
    this.calculation();
  };

  handleOnChangeNetSalesValue = (value) => {
    this.setState({
      netSalesValue: value,
    });
    this.calculation();
  };
  handleOnChangePeriod = (value) => {
    this.setState({
      period: value,
    });
    this.calculation();
  };

  calculation = () => {
    let ra = this.state.accRecievableAmount;
    let nsv = this.state.netSalesValue;
    let p = this.state.period;

    let ot = ((ra / nsv) * p).toFixed(2);

    if (isFinite(ot)) {
      this.setState({
        outstanding: ot,
      });
    } else {
      this.setState({
        outstanding: 0,
      });
    }
  };

  render() {
    let {
      accRecievableAmount,
      netSalesValue,
      period,
      outstanding,
    } = this.state;
    return (
      <div className="discounting-calculator row">
        <div className="col-lg-6 col-md-12">
          {/* Accounts Recievables Amount  */}
          <h4 className="slider-heading">
            Accounts Receivable
            <label>
              <sup>$</sup>
              {accRecievableAmount}
            </label>
          </h4>
          <Slider
            min={100}
            max={625000}
            step={100}
            value={accRecievableAmount}
            tooltip={false}
            onChange={this.handleOnChangeAccRecievableAmount}
          />

          {/* Net Sales Value  */}
          <h4 className="slider-heading">
            Credit Sales Value
            <label>
              <sup>$</sup>
              {netSalesValue}
            </label>
          </h4>
          <Slider
            min={100}
            max={625000}
            step={100}
            value={netSalesValue}
            tooltip={false}
            onChange={this.handleOnChangeNetSalesValue}
          />

          {/* Period  */}
          <h4 className="slider-heading">
            Sales Period
            <label>
              {period}
              <sup>days</sup>
            </label>
          </h4>
          <Slider
            min={1}
            max={365}
            step={1}
            value={period}
            tooltip={false}
            onChange={this.handleOnChangePeriod}
          />
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="card">
            <label>Days Sales Outstanding</label>
            <h2>{outstanding}</h2>
            <p>
              On average, it takes your business {outstanding} days to collect
              payment on outstanding accounts receivables
            </p>
            {outstanding > 7 && (
              <label className="outstanding-warning">
                Not happy with your DSO?{" "}
                <a onClick={() => scrollTo("#apply-for-financing")}>
                  Find how we can help you reduce it.
                </a>
              </label>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DiscountingCalculator;
